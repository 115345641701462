import { RegistrationForm } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const FormWrapper = styled.div`
  margin-top: 66px;
`

const AccountCreationPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const goToVerification = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('confirmAccount'))
  }, [navigate])

  const goToForgetPassword = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('forgotPasswordStart'))
  }, [navigate])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.createAccount}`} />
        </Helmet>
      )}
      <FormWrapper>
        <RegistrationForm
          baseUrl={window.brandConfig.baseUrl}
          language={language}
          goToVerification={goToVerification}
          goToForgetPassword={goToForgetPassword}
          ppLink={window.brandConfig.privacyPolicyUrl}
          tosLink={window.brandConfig.termsOfServiceUrl}
          api={window.brandConfig.api}
        />
      </FormWrapper>
    </LayoutCenter>
  )
}

export default AccountCreationPage
